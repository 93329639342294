import React from 'react'
import Lottie from "lottie-react"
import animationData from "../../lottie/searchAnimation.json"

function NotFound() {
  return (
    <div>
        <div className='pt-20 mx-auto text-center max-w-md'>
            <h1 className=' font-Montserrat text-3xl font-bold text-zinc-800 dark:text-zinc-200'>Oops! You seem to be lost.</h1>
            <Lottie animationData={animationData}/>
        </div>
    </div>
  )
}

export default NotFound