import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { MdMail } from "react-icons/md";
import React from 'react'
import {useState, useEffect } from 'react';

function About() {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollDown = () => {
    if (document.documentElement.scrollTop < 800) {
      document.getElementById('experience').scrollIntoView();
    } else if (document.documentElement.scrollTop < 1500) {
      document.getElementById('skills').scrollIntoView();
    } else if (document.documentElement.scrollTop >= 1500) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <section className="min-h-screen">
        <div className="text-center mx-auto mt-10 text-md py-5 gap-y-2 leading-8 text-zinc-800 md:text-lg max-w-xl dark:text-gray-200 transition-colors duration-1000">
          <p className="text-3xl pb-10">Hello. I'm <span className="text-pink-500"> Kevin</span>.</p>
          <p className="pb-10">I'm a student developer from San Diego, CA.</p>
          <p className="pb-10">I'm currently in my third year of studying Computer Science at UC San Diego.</p>
          <p                  >
            I've recently been exploring app development with <span className="underline italic">Expo</span> and <span className="underline italic">AWS Amplify</span>. Many new projects are coming soon!</p>
          <p></p>
        </div>
        <hr id="hrLine" class="w-48 h-1 mx-auto my-4 bg-gray-700 border-0 rounded md:my-10"/>
        <div className="justify-center text-2xl flex gap-16 pt-5 text-pink-500">
          <div className="tooltip tooltip-bottom" data-tip="GitHub">
            <a target="_blank" rel="noreferrer" href="https://github.com/kevin-dough" ><AiFillGithub /></a>
          </div>
          <div className="tooltip tooltip-bottom" data-tip="LinkedIn">
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kevintdo50/" ><AiFillLinkedin /></a>
          </div>
          <div className="tooltip tooltip-bottom" data-tip="Email">
            <a target="_blank" rel="noreferrer" href="mailto:kevin12345do@gmail.com" ><MdMail /></a>
          </div>
        </div>
      </section>
      {/* <p className=" text-white fixed bottom-32 right-12">{scrollTop}</p> */}
          <IoIosArrowDropdownCircle id="scrollButton" onClick={scrollDown} className={`${scrollTop >= 1500 ? "rotate-180" : ""} bottom-12 right-12 fixed text-black dark:text-white hover:text-pink-500 hover:scale-150 delay-200 text-3xl lg:text-4xl transition ease-in-out duration-200 hidden sm:inline`}/>

        <section id="experience" className="min-h-screen">
          <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 className='text-3xl font-bold text-black dark:text-white transition-colors duration-1000'>Work Experience</h1>
          <div className='py-5 flex gap-5 flex-wrap'>

          <div className="card card-compact w-full bg-zinc-200 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
              <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
                  <h2 className="card-title">Vetscribe by Rito Labs</h2>
                  <i className="-mt-3">Software Engineering Intern</i>
                  <div>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Developing a full stack web and mobile application using AWS Amplify, Expo, and React.</p>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Improved a live recording waveform and toast notifications to give feedback and status to users after performing actions.</p>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Created key AWS lambda functions to help users efficiently delete sections of their templates.</p>
                  </div>
                  <div className="card-actions justify-between">
                    <div className='gap-3 flex flex-wrap'>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">Expo</div> 
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">AWS Amplify</div>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">React Native</div>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">GraphQL</div>  
                    </div>  
                    <div>
                      <p>July 2024 - Present</p>   
                    </div>     
                </div>
              </div>
            </div>

            <div className="card card-compact w-full bg-zinc-200 shadow-md dark:bg-zinc-800 transition-colors duration-1000">
              <div className="card-body text-zinc-900 dark:text-white transition-colors duration-1000">
                  <h2 className="card-title">System Energy Efficiency (SEE) Lab UC San Diego</h2>
                  <i className="-mt-3">Student Research Assistant</i>
                  <div>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Using Hyperdimensional computing (HDC) for efficient mass spectrometry data clustering.</p>
                    <p className="m-1 bg-zinc-300 dark:bg-zinc-700 p-1 inline-block">Installing and running mass spectrometry clustering tools on CUDA-1 and CUDA-2 servers and collecting data on speed and accuracy.</p>
                  </div>
                  <div className="card-actions justify-between">
                    <div className='gap-3 flex flex-wrap'>
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">Docker</div> 
                      <div className="badge badge-outline text-zinc-900 dark:text-white transition-colors duration-1000">Hyperdimensional Computing</div>
                    </div>  
                    <div>
                      <p>May 2024 - Present</p>   
                    </div>     
                </div>
              </div>
            </div>

            

          </div>
          </div>
        </section>

      <section className="min-h-screen">
        <div className="pt-16 text-black dark:text-white transition-colors duration-1000">
          <h1 id="skills" className="text-3xl font-bold mb-2">Technical Skills</h1>
          {/* <div className="flex gap-3">(
            <span className="indicator-item badge bg-pink-500 text-black font-semibold">recently used</span> 
            <span className="indicator-item badge bg-sky-400 text-black font-semibold">learning</span> 
            )
          </div> */}
          <div>
            <h2 className="text-2xl">Languages</h2>
            <div className="p-2 gap-4 flex flex-wrap">
              {[
                ['Java',       ''],
                ['Python',     ''],
                ['C/C++',      ''],
                ['C#',         'hidden'],
                ['ARM/MIPS',   'hidden'],
                ['Bash',       'hidden'],
                ['HTML/CSS',   'hidden'],
                ['Javascript', 'hidden',],
                ['SystemVerilog', 'hidden',],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-200 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  <span className={`${highlight} indicator-item badge bg-pink-500 border-pink-500`}></span> 
                  <h2 className="text-sm text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl">Tools & Frameworks</h2>
            <div className="p-2 gap-4 flex flex-wrap">
              {[
                ['JUnit',              'hidden'],
                ['GDB',                'hidden'],
                ['Quart/Flask',        'hidden'],
                ['React',              'hidden'],
                ['TailwindCSS',        'hidden'],
                ['Firebase',           'hidden'],
                ['Figma',              'hidden'],
                ['VSCode',             'hidden'],
                ['Android Studio',     'hidden'],
                ['Vercel',             'hidden'],
                ['Windows',            'hidden'],
                ['Ubuntu',             'hidden'],
                ['macOS',              'hidden'],
                ['Git',                'hidden'],
                ['Swift',              'hidden'],
                ['Unity',              'hidden'],
                ['ModelSim',           'hidden'],
                ['Quartus',            'hidden'],
                ['AWS Amplfiy',        'hidden'],
                ['GraphQL',            'hidden'],
                ['Expo',               'hidden'],
                ['Linear',             'hidden'],
                ['Docker',             'hidden'],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-200 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  {/* <span className={`${learning} indicator-item badge bg-sky-400`}></span>  */}
                  <span className={`${highlight} indicator-item badge bg-pink-500 border-pink-500`}></span> 
                  <h2 className="text-sm text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl">Techniques</h2>
            <div className="p-2 gap-4 flex flex-wrap">
              {[
                ['Agile software process', ''],
                ['Behavior Driven Development (BDD)', 'hidden'],
                ['Unit Testing', 'hidden'],
                ['Object Mocking', 'hidden'],
                ['Continuous Integration', 'hidden'],
                ['Single Responsibility Principle (SRP)', 'hidden'],
                ['Dependency Inversion', 'hidden'],
                ['Open-Closed Principle', 'hidden'],
                ['Design Patterns (Strategy, Observer, MVP, Abstract Factory, Builder', ''],
              ].map(([item, highlight]) => (
                <div className="p-2 px-3 rounded-lg bg-zinc-200 shadow-md dark:bg-zinc-800 indicator transition-colors duration-1000">
                  <span className={`${highlight} indicator-item badge bg-pink-500 border-pink-500`}></span> 
                  <h2 className="text-sm text-zinc-900 dark:text-white transition-colors duration-1000">{item}</h2>
                </div>
              ))}
            </div>
          </div>
          
          
          

        </div>
      </section>
    </div>
  )
}

export default About