"use client";
import { BsFillMoonStarsFill, BsSunFill } from "react-icons/bs";


import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import Projects from './components/pages/Projects';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';

import { useState, useEffect } from 'react';

import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') || 'dark');
  const toggleMode = () => {
    setDarkMode(darkMode === 'dark' ? 'light' : 'dark');
  };
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);
  return (
    <BrowserRouter>
    <div className={darkMode === 'dark' ? "dark" : ""}>
      <main id="mainBackground" className="min-h-screen bg-white px-10 md:px-20 lg:px-40 dark:bg-zinc-900 cursor-pointerBlack dark:cursor-pointerWhite transition-colors duration-1000">
        <nav className="pt-20 flex justify-between">
          <a className="flex items-center gap-3" href="/">
            <img alt="logo" className="brightness-0 dark:brightness-100 transition duration-1000" height={40} width={40} src="/images/logo.png"/>
            {/* <span class="self-center text-2xl font-Montserrat tracking-wider font-semibold whitespace-nowrap dark:text-white">KEVIN DO</span> */}
          </a>
          <ul className="flex items-center gap-5">
            <li className="navLink">
              <NavLink to="/" activeStyle={{color: "red"}} className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">About</NavLink>
            </li>
            <li className="navLink">
              <NavLink to="/projects" activeStyle={{textDecoration: "underline"}} className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Projects</NavLink>
            </li>
            <li className="navLink">
              <a href="Kevin_Do_resume.pdf" download="Kevin_Do_resume.pdf" className="-ml-1 bg-pink-500 bg-opacity-60 p-1 self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Resume</a>
            </li>
            <li className="hidden min-[465px]:block">
              <BsFillMoonStarsFill onClick={toggleMode} className="cursor-linkBlack text-black text-2xl dark:hidden dark:text-white dark:cursor-linkWhite transition-colors duration-1000" />
              <BsSunFill onClick={toggleMode} className="cursor-linkBlack hidden text-2xl text-black dark:inline dark:text-white dark:cursor-linkWhite transition-colors duration-1000" />
            </li>
          </ul>
        </nav>
        <Routes className="opacity-100 transition-opacity duration-300">
          <Route path="/projects" element={<Projects />} />
          <Route path='*' element={<NotFound />}/>
          <Route path="/" element={<About />} />
        </Routes>
        
      </main>
    </div>
    </BrowserRouter>
  );
}

export default App;
